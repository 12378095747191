import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ConstructionRoundedIcon from "@mui/icons-material/ConstructionRounded";
import QueryStatsRoundedIcon from "@mui/icons-material/QueryStatsRounded";
import InsightsIcon from "@mui/icons-material/Insights";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import Groups3Icon from "@mui/icons-material/Groups3";
import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";

const items = [
  {
    icon: <QueryStatsRoundedIcon />,
    title: "Focus on Growth",
    description:
      "We believe in learning from mistakes and celebrate effort over perfection, fostering a culture of continuous improvement.",
  },
  {
    icon: <ConnectWithoutContactIcon />,
    title: "Open Communication",
    description:
      "We encourage honest and transparent dialogue across all levels, fostering trust and collaboration.",
  },
  {
    icon: <ConstructionRoundedIcon />,
    title: "Agile & Adaptable",
    description:
      "We embrace change and empower teams to quickly adjust to new situations and market demands.",
  },
  {
    icon: <InsightsIcon />,
    title: "Data-Driven Decisions",
    description:
      "We prioritize evidence-based decision-making, empowering employees with relevant data.",
  },
  {
    icon: <SelfImprovementIcon />,
    title: "Wellness & Flexibility",
    description:
      "We support a healthy work-life balance with fixed work hours and higher than standard paid time-offs that promote well-being.",
  },
  {
    icon: <Groups3Icon />,
    title: "Teamwork & Recognition",
    description:
      "We celebrate individual and team accomplishments, fostering a sense of belonging and shared success.",
  },
];

export default function Culture() {
  return (
    <Box
      id="Culture"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        color: "white",
        bgcolor: "#06090a",
      }}
    >
      <Container
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: "100%", md: "90%" },
            textAlign: { sm: "left", md: "center" },
          }}
        >
          <Typography component="h2" variant="h4">
            Culture
          </Typography>
          <Typography variant="body1" sx={{ color: "grey.400" }}>
            At Alphextra, we believe our greatest asset is our team. We foster a
            culture of mutual respect and open communication, where every
            employee feels valued and empowered to contribute their unique
            talents. Our flat hierarchy allows for open dialogue across all
            levels, ensuring ideas are heard and decisions are made
            collaboratively. We actively seek employee feedback and encourage
            continuous learning, providing opportunities for professional growth
            and development.
            <br />
            <br />
            We understand that a happy and healthy workforce is a productive
            one. At Alphextra, we reward and recognize achievements, both big
            and small. We offer competitive compensation packages and implement
            programs that celebrate individual and team successes. We prioritize
            work-life balance and encourage employees to disconnect and
            recharge. This creates a sustainable work environment where
            employees feel motivated, valued, and empowered to deliver their
            best work.
            <br />
            {/* <Link
              color="text.primary"
              href="https://pacific-estimate-67e.notion.site/Careers-Alphextra-29fc85d8ab024974b7fe7ffaa29103f3?pvs=4"
              target="_blank"
            >
              View Open Roles at Alphextra
            </Link> */}
          </Typography>
        </Box>
        <Grid container spacing={2.5}>
          {items.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Stack
                direction="column"
                color="inherit"
                component={Card}
                spacing={1}
                useFlexGap
                sx={{
                  p: 3,
                  height: "100%",
                  border: "1px solid",
                  borderColor: "grey.800",
                  background: "transparent",
                  backgroundColor: "grey.900",
                }}
              >
                <Box sx={{ opacity: "50%" }}>{item.icon}</Box>
                <div>
                  <Typography fontWeight="medium" gutterBottom>
                    {item.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: "grey.400" }}>
                    {item.description}
                  </Typography>
                </div>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

import './App.css';
import LandingPage from './landing-page/LandingPage'
//test
function App() {
  return (
    <LandingPage/>
  );
}

export default App;

import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import axlogo from "../../assets/axlogo.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
// import TwitterIcon from "@mui/icons-material/X";

const logoStyle = {
  width: "140px",
  height: "auto",
};

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" mt={1}>
      {"Copyright © "}
      <Link href="https://alphextra.com/">Alphextra&nbsp;</Link>
      {new Date().getFullYear()}
    </Typography>
  );
}

export default function Footer() {
  const [email, setEmail] = React.useState("");
  const [error, setError] = React.useState("");

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);

    if (!validateEmail(newEmail)) {
      setError("Invalid email address");
    } else {
      setError("");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateEmail(email)) {
      //   fetch("http://20.204.168.164:3000/subscribeToNewsletter", {
      //     method: "POST",
      //     headers: {
      //       "Content-Type": "application/json",
      //     },
      //     body: JSON.stringify({ email: email }),
      //   }).then(() => {
      //     console.log(email);
      //     alert("We have added you to our mailing list.");
      //   });
      // } else {
      //   alert("Please enter a valid email address.");
      // }
      // const url = `https://<dc>.api.mailchimp.com/3.0/lists/<list-id>/members/`;
      // const apiKey = "31ba31b966b5ed93bea80b1b8edbcbfd-us17";

      // const data = {
      //   email_address: email,
      //   status: "subscribed",
      // };

      // fetch(url, {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //     Authorization: `Basic ${btoa(`anystring:${apiKey}`)}`,
      //   },
      //   body: JSON.stringify(data),
      // })
      //   .then((response) => {
      //     response.json();
      //   })
      //   .then((data) => {
      //     console.log(data);
      //   });'
      const data = {
        email: email,
      };
      try {
        fetch("https://api.brevo.com/v3/contacts", {
          method: "POST",
          headers: {
            accept: "application/json",
            "api-key": process.env.REACT_APP_BREVO,
            "content-type": "application/json",
          },
          body: JSON.stringify(data),
        }).then((response) => {
          if (response.ok) {
            toast.success("Newsletter Subscribed");
          } else {
            console.log("Error adding contact");
          }
        });
      } catch (error) {
        toast.error("Error adding contact");
      }
    }
  };
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 4, sm: 8 },
        py: { xs: 8, sm: 10 },
        textAlign: { sm: "center", md: "left" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 4,
            minWidth: { xs: "100%", sm: "60%" },
          }}
        >
          <Box sx={{ width: { xs: "100%", sm: "60%" } }}>
            <Box sx={{ ml: "-15px" }}>
              <img src={axlogo} style={logoStyle} alt="logo of alphextra" />
            </Box>
            <Typography variant="body2" fontWeight={600} gutterBottom>
              Newsletter
            </Typography>
            <Typography variant="body2" color="text.secondary" mb={2}>
              Subscribe to our newsletter for monthly updates.
            </Typography>
            <Stack direction="row" spacing={1} useFlexGap>
              <TextField
                hiddenLabel
                size="small"
                variant="outlined"
                fullWidth
                aria-label="Enter your email address"
                placeholder="Your email address"
                inputProps={{
                  autocomplete: "off",
                  ariaLabel: "Enter your email address",
                }}
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={handleChange}
              />
              <Button
                variant="contained"
                color="primary"
                sx={{ flexShrink: 0 }}
                onClick={handleSubmit}
              >
                Subscribe
              </Button>
            </Stack>
            {error && <p style={{ color: "red" }}>{error}</p>}
          </Box>
        </Box>
        <Box
          sx={{
            display: { xs: "none", sm: "flex" },
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography variant="body2" fontWeight={600}>
            Alphextra
          </Typography>
          <Link color="text.secondary" href="#TechNInnovation">
            Tech & Innovation
          </Link>
          <Link color="text.secondary" href="#Culture">
            Culture
          </Link>
          <Link color="text.secondary" href="#faq">
            FAQs
          </Link>
        </Box>
        <Box
          sx={{
            display: { xs: "none", sm: "flex" },
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography variant="body2" fontWeight={600}>
            Company
          </Typography>
          <Link color="text.secondary" href="#">
            About us
          </Link>
          <Link
            color="text.secondary"
            href="https://pacific-estimate-67e.notion.site/Careers-Alphextra-29fc85d8ab024974b7fe7ffaa29103f3?pvs=4"
            target="_blank"
          >
            Careers
          </Link>
        </Box>
        <Box
          sx={{
            display: { xs: "none", sm: "flex" },
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography variant="body2" fontWeight={600}>
            Reach out
          </Typography>
          <Link
            color="text.secondary"
            href="mailto:ritesh.garg@alphextra.com"
            target="_blank"
          >
            Contact Us
          </Link>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          pt: { xs: 4, sm: 8 },
          width: "100%",
          borderTop: "1px solid",
          borderColor: "divider",
        }}
      >
        <div>
          {/* <Link color="text.secondary" href="#">
            Privacy Policy
          </Link>
          <Typography display="inline" sx={{ mx: 0.5, opacity: 0.5 }}>
            &nbsp;•&nbsp;
          </Typography>
          <Link color="text.secondary" href="#">
            Terms of Service
          </Link> */}
          <Copyright />
        </div>
        <Stack
          direction="row"
          justifyContent="left"
          spacing={1}
          useFlexGap
          sx={{
            color: "text.secondary",
          }}
        >
          {/* <IconButton
            color="inherit"
            href="https://github.com"
            aria-label="GitHub"
            sx={{ alignSelf: "center" }}
          >
            <GitHubIcon />
          </IconButton>
          <IconButton
            color="inherit"
            href="https://twitter.com"
            aria-label="X"
            sx={{ alignSelf: "center" }}
          >
            <TwitterIcon />
          </IconButton> */}
          <IconButton
            color="inherit"
            href="https://www.linkedin.com/company/alphextra/"
            target="_blank"
            aria-label="LinkedIn"
            sx={{ alignSelf: "center" }}
          >
            <LinkedInIcon />
          </IconButton>
        </Stack>
      </Box>
      <ToastContainer autoClose={1000} />
    </Container>
  );
}

import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function FAQ() {
  const [expanded, setExpanded] = React.useState(false);
  const FAQList = [
    {
      panel: "panel1",
      ariacontrols: "panel1d-content",
      id: "panel1d-header",
      question: "What does Alphextra do?",
      answer: [
        "Alphextra is a financial technology firm specializing in algorithmic trading solutions, specifically focused on mid and low latency execution.  This means they develop and use computer algorithms to make trades in financial markets at incredibly high speeds. Their goal is to achieve these trades with minimal delays to capitalize on fleeting market opportunities.",
      ],
    },
    {
      panel: "panel2",
      ariacontrols: "panel2d-content",
      id: "panel2d-header",
      question: "What sets Alphextra apart from its competitors?",
      answer: [
        "People: We believe that what differentiates a good workplace from the other is the people who are a part of it and we are proud to be a small team of enthusiasts who believe in building the best products while ensuring that everyday is a fun day at work.",
      ],
    },
    {
      panel: "panel3",
      ariacontrols: "panel3d-content",
      id: "panel3d-header",
      question: "Is Alphextra hiring?",
      answer: [
        "We are always looking to hire the best folks in the industry. If you think that you can add value to Alphextra, just drop us an email at ritesh.garg@alphextra.com",
      ],
    },
    {
      panel: "panel4",
      ariacontrols: "panel4d-content",
      id: "panel4d-header",
      question: "Who are the founders of Alphextra?",
      answer: [
        "Alphextra is built on the solid foundation of its founders' experience. Both co-founders have over 15 years of experience working across different teams and locations, bringing valuable insights to the company.",
        "",
        " One co-founder brings a strong financial background, with an MBA in finance and entrepreneurship. They've successfully launched and grown startups in India and abroad, demonstrating their ability to turn ideas into reality. The other co-founder has over a decade of experience in the financial markets, specializing in algorithmic trading.",
        "",
        " Their deep understanding of the financial landscape positions Alphextra to make informed decisions and utilize cutting-edge technology. Together, the co-founders' experience and diverse skillsets create a strong foundation for Alphextra's success. Their combined knowledge and passion for finance position the company for an exciting future.",
      ],
    },
  ];

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Container
      id="faq"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Typography
        component="h2"
        variant="h4"
        color="text.primary"
        sx={{
          width: { sm: "100%", md: "60%" },
          textAlign: { sm: "left", md: "center" },
        }}
      >
        Frequently asked questions
      </Typography>
      <Box sx={{ width: "100%" }}>
        {FAQList.map((faq, index) => (
          <Accordion
            key={index}
            expanded={expanded === faq.panel}
            onChange={handleChange(faq.panel)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={faq.ariacontrols}
              id={faq.id}
            >
              <Typography component="h3" variant="subtitle2">
                {faq.question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                key={index}
                variant="body2"
                gutterBottom
                sx={{ maxWidth: { sm: "100%", md: "70%" } }}
              >
                {faq.answer.map((answer, index) => {
                  return (
                    <span>
                      {answer}
                      <br />
                    </span>
                  );
                })}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
      {/* <Box sx={{ width: '100%' }}>
        <Accordion
          expanded={expanded === 'panel1'}
          onChange={handleChange('panel1')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1d-content"
            id="panel1d-header"
          >
            <Typography component="h3" variant="subtitle2">
              How do I contact customer support if I have a question or issue?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: '100%', md: '70%' } }}
            >
              You can reach our customer support team by emailing
              <Link> support@email.com </Link>
              or calling our toll-free number. We&apos;re here to assist you
              promptly.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel2'}
          onChange={handleChange('panel2')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2d-content"
            id="panel2d-header"
          >
            <Typography component="h3" variant="subtitle2">
              Can I return the product if it doesn&apos;t meet my expectations?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: '100%', md: '70%' } }}
            >
              Absolutely! We offer a hassle-free return policy. If you&apos;re not
              completely satisfied, you can return the product within [number of
              days] days for a full refund or exchange.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel3'}
          onChange={handleChange('panel3')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3d-content"
            id="panel3d-header"
          >
            <Typography component="h3" variant="subtitle2">
              What makes your product stand out from others in the market?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: '100%', md: '70%' } }}
            >
              Our product distinguishes itself through its adaptability, durability,
              and innovative features. We prioritize user satisfaction and
              continually strive to exceed expectations in every aspect.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel4'}
          onChange={handleChange('panel4')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4d-content"
            id="panel4d-header"
          >
            <Typography component="h3" variant="subtitle2">
              Is there a warranty on the product, and what does it cover?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: '100%', md: '70%' } }}
            >
              Yes, our product comes with a [length of warranty] warranty. It covers
              defects in materials and workmanship. If you encounter any issues
              covered by the warranty, please contact our customer support for
              assistance.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box> */}
    </Container>
  );
}

import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import AppAppBar from "./components/AppAppBar";
import Hero from "./components/Hero";
import Culture from "./components/Culture";
import TechNInnovation from "./components/TechNInnovation";
import FAQ from "./components/FAQ";
import Footer from "./components/Footer";
import getLPTheme from "./getLPTheme";

import { SpeedInsights } from "@vercel/speed-insights/react";

// const defaultTheme = createTheme({});

export default function LandingPage() {
  const [mode, setMode] = React.useState("dark");
  // const [showCustomTheme, setShowCustomTheme] = React.useState(true);
  // Landing page
  const LPtheme = createTheme(getLPTheme(mode));

  const toggleColorMode = () => {
    setMode((prev) => (prev === "dark" ? "light" : "dark"));
  };

  // const toggleCustomTheme = () => {
  //   setShowCustomTheme((prev) => !prev);
  // };

  return (
    <ThemeProvider theme={LPtheme}>
      <SpeedInsights />
      <CssBaseline />
      <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
      <Hero />
      <Box sx={{ bgcolor: "background.default" }}>
        <TechNInnovation />
        <Divider />
        <Culture />
        <Divider />
        <FAQ />
        <Divider />
        <Footer />
      </Box>
    </ThemeProvider>
  );
}
